import React, { useEffect } from "react";
import Layout from '../components/layout'
import { graphql } from "gatsby"
import CircleLink from "../components/circle-link";
import gsap from "gsap/gsap-core";
import utils from "../components/utils";
import { scroll } from "../theme";
import SEO from "../components/seo";
import { DEFAULT_INNER_COMPONENT_DELAY_MS } from "../constants";

const resolveConfig = require("tailwindcss/resolveConfig");
const tailwindConfig = require("../../tailwind.config.js");
const fullConfig = resolveConfig(tailwindConfig);

export default function ContactPage({ data }) {
  useEffect(() => {
    window.locomotiveScroll.stop()
    utils.resetPage(true)
    utils.fadeNav(true)
    gsap.to('.contact-form-top', {
      opacity: 1,
      ease: "power2.out",
      duration: 0.7,
      onComplete: () => {
        utils.fadeNav(true)
        document.body.style.backgroundColor = fullConfig.theme.colors.purple
        window.locomotiveScroll.start()
        gsap.to('#nav', {
          opacity: 1,
          duration: 0.7,
          ease: "power2.out"
        })
      }
    })
  }, [])

  const page = data.contentfulPageContact

  return (
    <Layout hideFooter={true}>
      <SEO
        title={page.meta.title}
        description={page.meta.description}
        image={page.meta.image.fluid.src}
      />
      <section id="contact" className="bg-dark text-sand lg:min-h-screen" >
        <div className="contact-form-top container px-0 lg:px-12 p-12 lg:min-h-screen flex items-end pt-48 pt:mt-0" style={{
          opacity: 0
        }}>
          <div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-32">
              <div>
                <h2 className="font-sailec text-lg mb-20 max-w-lg">
                  {page.contactTitle}
                </h2>
                <form id="contact-form" name="Contact Us"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/thankyou"
                  className="max-w-lg">
                  <input type="hidden" name="form-name" value="Contact Us" />
                  <input required type="text" name="name" placeholder="Name" />
                  <input required type="email" name="email" placeholder="Email" />
                  <input required type="text" name="phone" placeholder="Phone" />
                  <select required name="service" placeholder="How would you like to be contacted?">
                    <option value="" disabled selected>How would you like to be contacted?</option>
                    <option value="Email">Email</option>
                    <option value="Phone">Phone</option>
                  </select>
                  <div>
                    <label className="font-mabry uppercase text-sm" htmlFor="message">Message</label>
                  </div>
                  <textarea required name="message" rows="8"></textarea>
                  <CircleLink inverse={true} title="Submit" isSubmit={true} />
                </form>
              </div>

              <div className="mt-40 lg:mt-0">
                <h2 className="font-sailec text-lg mb-20">
                  {page.altTitle}
                </h2>
                <div className="lg:grid lg:grid-cols-2 lg:gap-24">
                  <div>
                    <div className="mb-12">
                      <h3 className="font-mabry uppercase text-sm pb-4 border-b mb-4 border-light-sand">Contact</h3>
                      <div><a className="link-generic" href="mailto:admin@lewisandtrovas.com">admin@lewisandtrovas.com</a></div>
                      <a className="link-generic" href="tel:0733332858">(07) 3333 2858</a>
                    </div>
                    <div className="mb-12">
                      <h3 className="font-mabry uppercase text-sm pb-4 border-b border-light-sand mb-4">Postal Address</h3>
                        GPO Box 3222<br />Brisbane CBD 4001
                      </div>
                  </div>
                  <div>
                    <div className="mb-12">
                      <h3 className="font-mabry uppercase text-sm pb-4 border-b border-light-sand mb-4">Location</h3>
                      <a className="link-generic" target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/AD2gYnvJj4sR9Cwh7">Suite 702, Level 7, 10 Market St, Brisbane CBD 4000</a>
                    </div>
                    <div className="mb-12">
                      <h3 className="font-mabry uppercase text-sm pb-4 border-b border-light-sand mb-4">Social</h3>
                      <div><a className="link-generic" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/lewis-and-trovas-family-lawyers">LinkedIn</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
{
  contentfulPageContact {
    altTitle
    contactTitle
    meta {
      title
      description
      image {
        fluid {
          src
        }
      }
    }
  }
}
`